import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import { SET_RETAILER } from "../../../../redux/app/actions";

import { validateObjectData, validateArrayData } from "../../../../utils";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Divider,
  List,
  Typography,
  Autocomplete,
  TextField,
  useMediaQuery,
} from "@material-ui/core";

// style constant
const useStyles = makeStyles((theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: "2px",
    marginBottom: "10px",
  },
}));

const allOption = "All";

const RetailerDropdown = ({ t }) => {
  const classes = useStyles();
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleRetailerChange = (retailerSelected) => {
    dispatch({
      type: SET_RETAILER,
      retailerSelected,
    });
  };

  return (
    <React.Fragment>
      <List
        subheader={
          <Typography
            variant="caption"
            className={classes.menuCaption}
            display="block"
            gutterBottom
          >
            {t("Retailer")}
          </Typography>
        }
      >
        <Autocomplete
          // disablePortal
          disableClearable={true}
          id="retailer"
          limitTags={1}
          multiple
          options={
            validateArrayData(appState.retailersAccessList)
              ? [allOption, ...appState.retailersAccessList]
              : []
          }
          value={appState.retailerSelected || []}
          onChange={(event, newValue) => {
            if (
              newValue !== null &&
              newValue.length > 0 &&
              newValue[newValue.length - 1] === allOption
            ) {
              return handleRetailerChange(appState.retailersAccessList);
            }
            return handleRetailerChange(newValue);
          }}
          getOptionLabel={(option) => option || ""}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} color="secondary" placeholder="Search" />
          )}
        />
      </List>

      <Divider className={classes.menuDivider} />
    </React.Fragment>
  );
};

RetailerDropdown.propTypes = {};

export default withTranslation()(RetailerDropdown);
