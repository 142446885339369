import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

// Material Ui
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Paper,
  List,
  Typography,
  Autocomplete,
  TextField,
  Hidden,
  Divider,
} from "@material-ui/core";
import { validateString, validateArrayData } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  paperClass: {
    padding: "18px !important",
    [theme.breakpoints.up("md")]: {
      padding: "16px !important",
      //   borderRadius: "3px !important",
    },
  },
}));

const allOption = "All";

const RetailerDropdown = ({ handleRetailerChange, history, t }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // State's
  const appState = useSelector((state) => state.app);

  return (
    <>
      <List
        subheader={
          <Typography
            variant="caption"
            className={classes.menuCaption}
            display="block"
            gutterBottom
          >
            Retailer
          </Typography>
        }
      >
        <Autocomplete
          // disablePortal
          disableCloseOnSelect
          disableClearable={true}
          id="retailer"
          limitTags={1}
          multiple
          options={
            validateArrayData(appState.retailersAccessList)
              ? [allOption, ...appState.retailersAccessList]
              : []
          }
          value={appState.retailerSelected || []}
          onChange={(event, newValue) => {
            if (
              newValue !== null &&
              newValue.length > 0 &&
              newValue[newValue.length - 1] === allOption
            ) {
              return handleRetailerChange(appState.retailersAccessList);
            }
            return handleRetailerChange(newValue);
          }}
          getOptionLabel={(option) => option || ""}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} color="secondary" placeholder="Search" />
          )}
        />
      </List>

      <Divider className={classes.menuDivider} />
    </>
  );
};

RetailerDropdown.propTypes = {};

export default withTranslation()(withRouter(RetailerDropdown));
