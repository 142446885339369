import React, { Component, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

// Import
import {
  RequestQuestionsByTopicEndpoint,
  RequestUMGQueryEndpoint,
} from "../components/AxiosEndpoints";

import {
  SET_TOPIC_LIST,
  SET_TOPIC,
  SET_QUESTIONS,
  SET_IS_QUESTIONS_LOADING,
} from "../redux/app/actions";

const withSearchComponent = (Component) => {
  const wrapperComponent = (props) => {
    const appState = useSelector((state) => state.app);

    const { umgSearchQuery, topicSelected } = appState;

    const dispatch = useDispatch();

    // Get Question by Topic
    const handleGetQuestionsByTopic = (val) => {
      console.log("handleGetQuestionsByTopic", val);
      let queryObject = { ...val };
      dispatch({
        type: SET_IS_QUESTIONS_LOADING,
        isQuestionsLoading: true,
      });
      dispatch({
        type: SET_QUESTIONS,
        questionsAccessList: [],
      });

      RequestQuestionsByTopicEndpoint(queryObject)
        .then((res) => {
          if (res.status === 200) {
            let responseData = res.data;
            console.log("Questions responseData", responseData);
            dispatch({
              type: SET_QUESTIONS,
              questionsAccessList: responseData["questions"]
                ? responseData["questions"]
                : [],
            });
            dispatch({
              type: SET_IS_QUESTIONS_LOADING,
              isQuestionsLoading: false,
            });
          } else {
            dispatch({
              type: SET_QUESTIONS,
              questionsAccessList: [],
            });
            dispatch({
              type: SET_IS_QUESTIONS_LOADING,
              isQuestionsLoading: false,
            });
          }
        })
        .catch((error) => {
          console.log("RequestQuestionsByTopicEndpoint****", error);
          dispatch({
            type: SET_QUESTIONS,
            questionsAccessList: [],
          });
          dispatch({
            type: SET_IS_QUESTIONS_LOADING,
            isQuestionsLoading: false,
          });
        });
    };

    const handleGetTopics = (val) => {
      dispatch({
        type: SET_TOPIC_LIST,
        topicAccessList: [],
      });

      dispatch({
        type: SET_TOPIC,
        topicSelected: "",
      });

      // const url = "https://demo-apps.ongil.io//api/v1/get_questions";
      // const token = "201de7570fd24156884dcb727654b55facfb0db4";

      const url = "/api/v1/get_questions";
      const token = localStorage.getItem("token");

      axios({
        method: "GET",
        url,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        params: {
          is_franchise:
            appState["selectedRetailerType"] === "Non apparel"
              ? appState["isFranchise"].value
              : "All",
          ...val,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            let responseData = res.data;
            console.log("topicAccessList", responseData);
            dispatch({
              type: SET_TOPIC_LIST,
              topicAccessList: responseData["topics"]
                ? ["all", ...responseData["topics"]]
                : [],
            });
            dispatch({
              type: SET_TOPIC,
              topicSelected: "all",
            });
            handleGetQuestionsByTopic({
              ...val,
              topic: "all",
            });
          } else {
            dispatch({
              type: SET_TOPIC_LIST,
              topicAccessList: [],
            });

            dispatch({
              type: SET_TOPIC,
              topicSelected: "",
            });
          }
        })
        .catch((error) => {
          console.log("RequestTopicListEndpoint****", error);
          dispatch({
            type: SET_TOPIC_LIST,
            topicAccessList: [],
          });

          dispatch({
            type: SET_TOPIC,
            topicSelected: "",
          });
        });
    };

    const handleFetchSearchData = (setState, val) => {
      if (umgSearchQuery) {
        let queryObject = {
          ...val,
          query: umgSearchQuery,
          topic: topicSelected,
        };

        console.log("inside hoc");

        setState((prev) => {
          return {
            ...prev,
            isLoading: true,
          };
        });

        RequestUMGQueryEndpoint(queryObject)
          .then((res) => {
            setState((prev) => {
              return {
                ...prev,
                searchData: res.data,
                reportData: res.data["report_list"],
                selectedSku: res.data["report_list"][0],
                newProductsData: res.data["new_prod_dict"]["sub_category"]
                  ? res.data["new_prod_dict"]["sub_category"]
                  : {},
                isLoading: false,
              };
            });
          })
          .catch((error) =>
            setState({
              searchData: {},
              reportData: [],
              selectedSku: {},
              newProductsData: {},
              isLoading: false,
            })
          );
      } else return;
    };

    return (
      <Component
        {...props}
        handleGetQuestionsByTopic={handleGetQuestionsByTopic}
        handleGetTopics={handleGetTopics}
        handleFetchSearchData={handleFetchSearchData}
      />
    );
  };

  return wrapperComponent;
};

export default withSearchComponent;
