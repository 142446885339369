import React from "react";
import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@material-ui/core";

// project imports
import config from "../../../config";

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
  return (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
      <img
        src={"/static/img/ongil-2x.png"}
        alt="logo"
        style={{ width: "113px" }}
      />
    </ButtonBase>
  );
};

export default LogoSection;
