import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import {
  SET_COUNTRY,
  SET_MENU,
  SET_IS_LOADING_SPIN,
  SET_RETAILER_LIST,
  SET_RETAILER,
  SET_CATEGORY_LIST,
  SET_CATEGORY,
  SET_IS_RETAILER_TYPE,
  SET_QUESTIONS,
  SET_TOPIC_LIST,
  SET_TOPIC,
  SET_IS_QUESTIONS_LOADING,
} from "../../../../redux/app/actions";

import axios from "axios";

import {
  RequestCategoryBrandEndpoint,
  RequestQuestionsByTopicEndpoint,
} from "../../../../components/AxiosEndpoints";
import { validateObjectData, validateArrayData } from "../../../../utils";
import { retailerType, retailerOptions } from "../../../../constants";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Divider,
  List,
  Typography,
  Autocomplete,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import withSearchComponent from "../../../../HOC/withSearchComponent";

// style constant
const useStyles = makeStyles((theme) => ({
  menuCaption: {
    ...theme.typography.menuCaption,
  },
  subMenuCaption: {
    ...theme.typography.subMenuCaption,
  },
  menuDivider: {
    marginTop: "2px",
    marginBottom: "10px",
  },
}));

const ProductType = ({ t, handleGetTopics }) => {
  const classes = useStyles();
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleRetailerTypeChange = (value) => {
    dispatch({
      type: SET_IS_RETAILER_TYPE,
      selectedRetailerType: value,
    });

    dispatch({
      type: SET_IS_LOADING_SPIN,
      isLoadingSpin: true,
    });

    let retailersAccessList = retailerOptions[value];

    dispatch({
      type: SET_RETAILER_LIST,
      retailersAccessList: [],
    });
    dispatch({
      type: SET_RETAILER,
      retailerSelected: "",
    });
    dispatch({
      type: SET_CATEGORY_LIST,
      categoryAccessList: [],
    });
    dispatch({
      type: SET_CATEGORY,
      category: "",
    });

    handleGetTopics({ retailer: [retailersAccessList[0]] });

    RequestCategoryBrandEndpoint({
      retailer: [retailersAccessList[0]],
    })
      .then((res) => {
        if (res.status === 200 && validateObjectData(res.data)) {
          dispatch({
            type: SET_RETAILER_LIST,
            retailersAccessList,
          });
          dispatch({
            type: SET_RETAILER,
            retailerSelected: [retailersAccessList[0]],
          });
          dispatch({
            type: SET_CATEGORY_LIST,
            categoryAccessList: res.data["category"],
          });
          dispatch({
            type: SET_CATEGORY,
            category: [res.data["category"][0]],
          });
          dispatch({
            type: SET_IS_LOADING_SPIN,
            isLoadingSpin: false,
          });
        } else {
          dispatch({
            type: SET_IS_LOADING_SPIN,
            isLoadingSpin: false,
          });
        }
      })
      .catch((error) => {
        console.log(appState);
        dispatch({
          type: SET_IS_LOADING_SPIN,
          isLoadingSpin: false,
        });
      });
  };

  return (
    <React.Fragment>
      <List
        subheader={
          <Typography
            variant="caption"
            className={classes.menuCaption}
            display="block"
            gutterBottom
          >
            Retailer Type
          </Typography>
        }
      >
        <Autocomplete
          // disablePortal
          disableClearable={true}
          id="retailer Type"
          options={retailerType}
          value={appState.selectedRetailerType || null}
          onChange={(event, newValue) => {
            console.log("Currr Retailer Type", newValue);
            handleRetailerTypeChange(newValue);
          }}
          getOptionLabel={(option) => option}
          fullWidth
          size="small"
          renderInput={(params) => (
            <TextField {...params} color="secondary" placeholder="Search" />
          )}
        />
      </List>

      <Divider className={classes.menuDivider} />
    </React.Fragment>
  );
};

ProductType.propTypes = {};

export default withTranslation()(withSearchComponent(ProductType));
