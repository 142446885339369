import { artistList } from "../../constants";
import * as actionTypes from "./actions";

export const artistState = {
  artistSelected: artistList[0],
};

const artistReducer = (state = artistState, action) => {
  switch (action.type) {
    case actionTypes.SET_ARTIST:
      return {
        ...state,
        artistSelected: action.activeArtist,
      };
    default:
      return state;
  }
};

export default artistReducer;
