import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import ArtistLayout from "../layouts/ArtistLayout";
import Loadable from "../components/Loadable";
// dashboard routing

const NeedsOpporPage = Loadable(
  lazy(() => import("../views/NeedsVsOppturnity/needs.opp.page"))
);
const NeedsOpporRoutes = () => {
  return (
    <Route>
      <Switch>
        <Route path="/needs-vs-opportunity" component={NeedsOpporPage} />
      </Switch>
    </Route>
  );
};

export default NeedsOpporRoutes;
