import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
//import MockData from "./data.json";

import {
  select,
  json,
  cluster,
  tree,
  hierarchy,
  linkHorizontal,
  zoom,
  event,
  scaleSqrt,
  extent,
} from "d3";
import CustomCardComponent from "../../../components/CustomCardComponent";

const margin = { top: 75, right: 50, bottom: 75, left: 50 };

const RadialTreeChart = ({ title, height, width, data, cardAction }) => {
  const svgRef = useRef();
  const gRef = useRef();
  const innerHeight = height - margin.top - margin.bottom;
  const innerWidth = width - margin.left - margin.right;

  function project(theta, r) {
    return [
      innerWidth / 2 + r * Math.sin(theta),
      innerHeight / 2 + r * Math.cos(theta) + 4,
    ];
  }

  const clusterLayout = cluster().size([2 * Math.PI, innerWidth / 2 - 150]);
  const root = hierarchy(data);
  const links = clusterLayout(root).links();
  const descendants = root.descendants();

  const dynamicFonts = scaleSqrt([15, 7]).domain(
    extent(descendants, (d) => d.depth)
  );

  const generateLinks = function (d) {
    if (d.parent === descendants[0]) {
      return "M" + project(d.x, d.y) + " " + project(d.parent.x, d.parent.y);
    } else {
      return (
        "M" +
        project(d.x, d.y) +
        "C" +
        project(d.x, (d.y + d.parent.y) / 2) +
        " " +
        project(d.parent.x, (d.y + d.parent.y) / 2) +
        " " +
        project(d.parent.x, d.parent.y)
      );
    }
  };

  const generateNode = function (d) {
    var theta = (-d.x / Math.PI) * 180 + 90;
    if (d.x > Math.PI) {
      theta += 180;
    }
    if (d.depth !== 3 && Math.abs(theta) < 30) {
      theta = 0;
    }
    if (d.depth > 1) {
      return "rotate(" + theta + ")";
    } else {
      return "";
    }
  };

  useEffect(() => {
    const zoomIns = zoom().scaleExtent([1 / 2, 4]);

    select(svgRef.current).call(
      zoomIns.on("zoom", function (event) {
        select(gRef.current).attr("transform", event.transform);
      })
    );
  }, []);

  return (
    <CustomCardComponent
      cardTitle={title}
      contentHeight={height}
      // isLoadingSpin={isLoading}
      // infoIconContent={infoIconContent}
      cardAction={cardAction}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <svg ref={svgRef} width={width} height={height}>
          <g ref={gRef} transform={`translate(${margin.left},${margin.top})`}>
            {descendants.slice(1).map((link, linkIndex) => (
              <path
                style={{ fill: "none", stroke: "#efb31e" }}
                key={linkIndex}
                d={generateLinks(link)}
              />
            ))}

            {descendants?.map((node, nodeIndex) => (
              <g
                key={nodeIndex}
                transform={"translate(" + project(node.x, node.y) + ")"}
              >
                <text
                  key={nodeIndex}
                  fontSize={dynamicFonts(node.depth) + "pt"}
                  transform={generateNode(node)}
                  textAnchor={
                    !node.children
                      ? node.x > Math.PI
                        ? "end"
                        : "start"
                      : "middle"
                  }
                  alignmentBaseline={"central"}
                  dx={
                    !node.children ? (node.x > Math.PI ? "-2px" : "2px") : "0px"
                  }
                >
                  {node.data.data.id}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
    </CustomCardComponent>
  );
};

RadialTreeChart.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  data: PropTypes.object,
};

RadialTreeChart.defaultProps = {
  height: 720,
  width: 730,
  //data: MockData,
};

export default RadialTreeChart;

// const RadialTreeChart = ({ height, width, data }) => {
// const svgRef = useRef();
// const gRef = useRef();
//     const innerHeight = height - margin.top - margin.bottom;
//     const innerWidth = width - margin.left - margin.right;

//     const treeLayout = tree().size([innerHeight, innerWidth]);
//     const root = hierarchy(data);
//     const links = treeLayout(root).links();
//     const linkPathGenerator = linkHorizontal()
//       .x((d) => d.y)
//       .y((d) => d.x);

//   useEffect(() => {
//     const zoomIns = zoom();

//     select(svgRef.current).call(
//       zoomIns.on("zoom", function (event) {
//         console.log(event.transform);
//         select(gRef.current).attr("transform", event.transform);
//       })
//     );
//   }, []);

//     return (
//       <svg ref={svgRef} width={width} height={height}>
//         <g ref={gRef} transform={`translate(${margin.left},${margin.top})`}>
//           {links.map((link, linkIndex) => (
//             <path
//               style={{ fill: "none", stroke: "#57bdc3" }}
//               key={linkIndex}
//               d={linkPathGenerator(link)}
//             />
//           ))}
//           {root.descendants()?.map((node, nodeIndex) => (
//             <text
//               key={nodeIndex}
//               x={node.y}
//               y={node.x}
//               dy={"0.32em"}
//               fontSize={3.2 - node.depth + "em"}
//               textAnchor={node.children ? "middle" : "start"}
//             >
//               {node.data.data.id}
//             </text>
//           ))}
//         </g>
//       </svg>
//     );
//   };
