// assets
import {
  IconCoin,
  IconAd,
  IconBuildingWarehouse,
  IconBusinessplan,
  IconSpeakerphone,
  IconListSearch,
} from "@tabler/icons";

import PivotTableChartOutlinedIcon from "@material-ui/icons/PivotTableChartOutlined";
import PsychologyOutlinedIcon from "@material-ui/icons/PsychologyOutlined";

import { TrendingUp } from "@material-ui/icons";

//-----------------------|| ANALYZE MENU ITEMS ||-----------------------//

const analyzeFeatureList = [
  // {
  //   id: "cat-overview",
  //   featureId: "cat_overview",
  //   title: "Overview",
  //   type: "item",
  //   url: "/app/overview",
  //   icon: IconListSearch,
  // },
  {
    id: "rneeds",
    featureId: "needs",
    title: "Needs",
    type: "item",
    url: "/app/needs",
    icon: PsychologyOutlinedIcon,
  },
  {
    id: "marketshare",
    featureId: "market_share",
    title: "MarketShare",
    type: "item",
    url: "/app/marketshare",
    icon: IconBusinessplan,
  },
  // {
  //   id: "growth",
  //   featureId: "growth",
  //   title: "Growth",
  //   type: "item",
  //   url: "/app/growth",
  //   icon: TrendingUp,
  // },
  {
    id: "whitespace",
    featureId: "whitespace",
    title: "White Space",
    type: "item",
    url: "/app/whitespace",
    icon: PivotTableChartOutlinedIcon,
  },
  // {
  //   id: "price-tracking",
  //   featureId: "price_tracking",
  //   title: "Price",
  //   type: "item",
  //   url: "/app/price-tracking",
  //   icon: IconCoin,
  // },
  // {
  //   id: "advertisement",
  //   featureId: "advertisement",
  //   title: "Advertisement",
  //   type: "item",
  //   url: "/app/advertisement",
  //   icon: IconAd,
  // },
  // {
  //   id: "stockout",
  //   featureId: "stock_out",
  //   title: "Stockout",
  //   type: "item",
  //   url: "/app/stockout",
  //   icon: IconBuildingWarehouse,
  // },
  {
    id: "search-insights",
    featureId: "search_insights",
    title: "Awareness",
    type: "item",
    url: "/app/awareness",
    icon: IconListSearch,
  },
  {
    id: "review-analysis",
    featureId: "review_analysis",
    title: "Consumer Voice",
    type: "item",
    url: "/app/consumer-voice",
    icon: IconSpeakerphone,
  },
];

export const analyze = (featuresAccessList) => {
  return {
    id: "analyze",
    title: "Analytics",
    type: "group",
    children: analyzeFeatureList,
  };
};
