// action - state management
import * as actionTypes from "./actions";
import moment from "moment";
import {
  defaultQuestions,
  retailerOptions,
  retailerType,
} from "../../constants";

const LanguageList = [
  { language: "English", language_code: "en" },
  { language: "Korean", language_code: "ko" },
  { language: "Japanese", language_code: "ja" },
];
const franchiseTypes = [
  { label: "All", value: "All" },
  { label: "Licensed", value: 1 },
];
// const franchiseTypes = [
//   { label: "Franchise", value: 1 },
//   { label: "Non-Franchise", value: 0 },
// ];

export const initialState = {
  isOpen: [], //for active default menu
  opened: true,
  appLanguage: LanguageList[0],
  LanguageList,
  // ** Initialize **
  isMainLoading: true,
  isLoadingSpin: true,
  // clientAccess
  clientSelected: "UMG",
  clientsAccessList: [],
  // feature access
  featuresAccessList: [],
  // countryAccess
  countrySelected: { country: "USA" },
  countryAccessList: [],
  // retailerAccess
  retailersAccessList: retailerOptions[retailerType[0]],
  selectedRetailerType: retailerType[0],
  retailerSelected: retailerOptions[retailerType[0]].slice(0, 1),
  start_date: moment().subtract(7, "d").format("YYYY-MM-DD"),
  end_date: moment().format("YYYY-MM-DD"),
  category: [],
  categoryAccessList: [],
  profileData: {},
  umgSearchQuery: "",
  franchiseOptions: franchiseTypes,
  isFranchise: franchiseTypes[0],
  topicAccessList: [],
  topicSelected: "",
  questionsAccessList: [],
  isQuestionsLoading: false,
  // umgSearchQuery:
  //   defaultQuestions[Math.floor(Math.random() * defaultQuestions.length)],
};

//-----------------------|| CUSTOMIZATION REDUCER ||-----------------------//

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_OPEN:
      const id = action.id;
      return {
        ...state,
        isOpen: [id],
      };
    case actionTypes.SET_MENU:
      return {
        ...state,
        opened: action.opened,
      };
    case actionTypes.SET_APP_LANGUAGE:
      return {
        ...state,
        appLanguage: action.appLanguage,
      };

    // action - app Initialize
    case actionTypes.SET_IS_MAIN_LOADING:
      return {
        ...state,
        isMainLoading: action.isMainLoading,
      };

    case actionTypes.SET_IS_LOADING_SPIN:
      return {
        ...state,
        isLoadingSpin: action.isLoadingSpin,
      };

    case actionTypes.SET_USER_PROFILE:
      return {
        ...state,
        profileData: action.profileData,
      };

    // feature access

    case actionTypes.SET_FEATURE_LIST:
      return {
        ...state,
        featuresAccessList: action.featuresAccessList,
      };

    // clientAccess
    case actionTypes.SET_CLIENT:
      return {
        ...state,
        clientSelected: action.clientSelected,
      };

    case actionTypes.SET_CLIENT_LIST:
      return {
        ...state,
        clientsAccessList: action.clientsAccessList,
      };

    // countryAccess
    case actionTypes.SET_COUNTRY:
      return {
        ...state,
        countrySelected: action.countrySelected,
      };

    case actionTypes.SET_COUNTRY_LIST:
      return {
        ...state,
        countryAccessList: action.countryAccessList,
      };

    // retailerAccess
    case actionTypes.SET_RETAILER_LIST:
      return {
        ...state,
        retailersAccessList: action.retailersAccessList,
      };
    case actionTypes.SET_RETAILER:
      return {
        ...state,
        retailerSelected: action.retailerSelected,
      };

    case actionTypes.SET_START_DATE:
      return {
        ...state,
        start_date: action.start_date,
      };
    case actionTypes.SET_END_DATE:
      return {
        ...state,
        end_date: action.end_date,
      };
    case actionTypes.SET_CATEGORY:
      return {
        ...state,
        category: action.category,
      };
    case actionTypes.SET_CATEGORY_LIST:
      return {
        ...state,
        categoryAccessList: action.categoryAccessList,
      };

    case actionTypes.SET_UMG_SEARCH_QUERY:
      return {
        ...state,
        umgSearchQuery: action.umgSearchQuery,
      };

    case actionTypes.SET_IS_FRANCHISE:
      return {
        ...state,
        isFranchise: action.isFranchise,
      };

    case actionTypes.SET_TOPIC_LIST:
      return {
        ...state,
        topicAccessList: action.topicAccessList,
      };

    case actionTypes.SET_TOPIC:
      return {
        ...state,
        topicSelected: action.topicSelected,
      };

    case actionTypes.SET_QUESTIONS:
      return {
        ...state,
        questionsAccessList: action.questionsAccessList,
      };

    case actionTypes.SET_IS_RETAILER_TYPE:
      return {
        ...state,
        selectedRetailerType: action.selectedRetailerType,
      };

    case actionTypes.SET_IS_QUESTIONS_LOADING:
      return {
        ...state,
        isQuestionsLoading: action.isQuestionsLoading,
      };

    default:
      return state;
  }
};

export default appReducer;
