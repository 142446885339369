import React from "react";
import { useSelector } from "react-redux";

import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline, StyledEngineProvider } from "@material-ui/core";

// routing
import Routes from "./routes";

// defaultTheme
import theme from "./theme";

// project imports
import NavigationScroll from "./components/NavigationScroll";
import RadialTreeChart from "./views/charts/RadialTreeChart";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme()}>
        <CssBaseline />
        <NavigationScroll>
          <Routes />
          {/* <RadialTreeChart height={1080} width={1080} /> */}
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
