import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";

// project imports
import Initialize from "../views/Initialize";
import Loadable from "../components/Loadable";
import PrivateRoute from "../components/PrivateRoute";
import ConsolidatedLayout from "../layouts/ConsolidatedLayout";

// dashboard routing
const ConsolidatedPage = Loadable(
  lazy(() => import("../views/ConsolidatedPage/consolidate.page"))
);

//-----------------------|| MAIN ROUTING ||-----------------------//

const ConsolidatedRoutes = () => {
  const location = useLocation();

  return (
    <Route path={["/app/consolidate"]}>
      <Initialize LayoutWrapperComponent={ConsolidatedLayout}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute path="/app/consolidate" component={ConsolidatedPage} />
        </Switch>
      </Initialize>
    </Route>
  );
};

export default ConsolidatedRoutes;
