import Api from "./Api";
import axios from "axios";
import { store } from "../redux/store";

//-----------------------|| Axios Get, Post & Error handle Functions ||-----------------------//

const axiosGetRequest = async (url) => {
  const token = localStorage.getItem("token");
  const currentState = store.getState();
  return await axios({
    method: "GET",
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    params: {
      is_franchise: currentState.app["retailerSelected"].includes("AMAZON")
        ? currentState.app["isFranchise"].value
        : "All",
    },
  });
};

const axiosPostRequest = async (url, data) => {
  const token = localStorage.getItem("token");

  return await axios({
    method: "POST",
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
    data,
  });
};

const axiosPostRequestAppEndpoints = async (url, data) => {
  const token = localStorage.getItem("token");
  const currentState = store.getState();
  let payload = {
    retailer: currentState.app["retailerSelected"], //need to be removed
    country: currentState.app["countrySelected"]["country"],
    client: currentState.app["clientSelected"],
    is_franchise:
      currentState.app["selectedRetailerType"] === "Non apparel"
        ? currentState.app["isFranchise"].value
        : "All",
    // start_date: currentState.app["start_date"], //need to be removed
    // end_date: currentState.app["end_date"], //need to be removed
    // category: currentState.app["category"], //need to be removed
    ...data,
  };

  return await axios({
    method: "POST",
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
      //Authorization: `Token 9960bc1a4982b62751db14f8c35927f177a5e4fc`,
    },
    data: payload,
  });
};

export const handleAuthorizationError = (error, history) => {
  console.log(error.message);
  localStorage.removeItem("token");
  history.push("/login");
  return;
};

//-----------------------|| Request Functions ||-----------------------//

// ********** Auth Endpoints ********** //
// Request user profile
export const RequestProfileEndpoint = () => {
  return axiosGetRequest(Api.profile);
};

// ********** Filter's Endpoints ********** //
export const RequestCategoryBrandEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.categoryBrandListFilterApi, data);
};

export const RequestAttributeListEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.attributeListApi, data);
};

// ********** Pricing Endpoints ********** //
// Request Pricing List Data
export const RequestPricingListEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.pricingProductListApi, data);
};

// Request Pricing List Data
export const RequestPricingTrendsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.pricingTrendsApi, data);
};

// ********** Alert Endpoints ********** //
// Request Alert Data
export const RequestAlertsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.alertsApi, data);
};

// ********** MarketShare Endpoints ********** //
// Request MarketShare Report Data
export const RequestMarketShareReportEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.marketShareApi, data);
};

// Request MarketShare timeseries Data
export const RequestMarketShareTimeSeriesEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.marketSharePepsiSeries, data);
};

// ********** Advertisement Endpoints ********** //
// Request Advertisement Types
export const RequestAdvertisementTypeEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.advertisementTypesList, data);
};

// Request Advertisement Count Brand level
export const RequestAdvertisementBrandCountEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.advertisementBrandCount, data);
};

// Request Key Advertisement
export const RequestKeyAdvertisementEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.advertisementKeyAds, data);
};

// Request Advertisement Trends
export const RequestAdvertisementTrendsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.advertisementBrandTrends, data);
};

// Request Advertisement Events - SKU
export const RequestAdvertisementSKUEventsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.advertisementSKUEventApi, data);
};

// Request Advertisement Events -Brand
export const RequestAdvertisementBrandEventsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.advertisementBrandEventApi, data);
};

// ********** Dashboard Endpoints ********** //

export const RequestDashboardInsightsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.dashboardInsights, data);
};

// ********** Revenue Endpoints ********** //

// Request Revenue Brand Trends
export const RequestRevenueBrandTrendsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.revenueTrendsBrand, data);
};

// Request Revenue SKU Trends
export const RequestRevenueSkuTrendsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.revenueTrendsSku, data);
};

// ********** Review Analysis ********** //

// Request Review Time Series
export const RequestReviewOverPeriodEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.reviewTimeSeries, data);
};

// Request Sentiment Rating
export const RequestSentimentRatingEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.reviewSentimentRating, data);
};

// Request Sentiment Comparison : reviewSentimentComparison
export const RequestSentimentComparisonEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.reviewSentimentComparison, data);
};

// Request Product/SKUid
export const RequestProductListByCategoryEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.reviewProductList, data);
};

export const RequestReviewCategoryBrandEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(
    Api.reviewCategoryBrandListFilterApi,
    data
  );
};

// Review Count Series
export const RequestReviewCountSeriesEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.reviewCountSeries, data);
};

// Cards API
export const RequestCardsApiEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.cardsApi, data);
};

// Attrs Word Cloud
export const RequestAttrsWordCloudEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.attributesWordCloud, data);
};
// Attrs Word Cloud
export const RequestWordCloudReviewsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.wordCloudReviews, data);
};
// ********** WhiteSpace Endpoints ********** //

export const RequestWhiteSpaceAtrribListEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.whiteSpaceAttribList, data);
};

export const RequestWhiteSpaceReportEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.whiteSpaceReport, data);
};

// ************ Search Insights *************** //
export const RequestSearchKeywordsReportTableEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.searchKeywordsReportTable, data);
};
export const RequestSearchKeywordsListEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.searchKeywordsList, data);
};
export const RequestSOVBrandsTrendEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.searchSovBrandsTrend, data);
};
export const RequestLowHighSovKeywordsEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.searchLowHighSovKeywords, data);
};
export const RequestAvgBrandsSovEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.searchAvgBrandsSov, data);
};
export const RequestSovBrandwiseReport = (data) => {
  return axiosPostRequestAppEndpoints(Api.sovBrandwiseReport, data);
};
export const RequestSovKeywordwiseReport = (data) => {
  return axiosPostRequestAppEndpoints(Api.sovKeywordwiseReport, data);
};
export const RequestSovKeywordsWeeklyTrendEndPoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.sovKeywordsWeeklyTrend, data);
};

// ************ Growth *************** //

export const RequestGrowthRevenueSeriesEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.growthRevenueSeriesApi, data);
};

export const RequestGrowthShareOfGrowthEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.growthShareofGrowth, data);
};

export const RequestFastestGrowingSkuEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.fastestGrowingSku, data);
};

// ************ Consolidated *************** //

export const RequestConsolidatedMonthonMonthRevenueEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(
    Api.consolidatedApiMonthonMonthRevenue,
    data
  );
};

// ************ UMG *************** //

export const RequestUMGQueryEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgQueryApi, data);
};

export const RequestTopicListEndpoint = () => {
  return axiosGetRequest(Api.umgGetQuestions);
};

export const RequestQuestionsByTopicEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgGetQuestions, data);
};

export const RequestCategoryAndFrachiseListEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgCategoryAndFranchiseList, data);
};

export const RequestTopicsAndTagsByCategoryEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgTopicsAndTagsByCategory, data);
};

export const RequestSubCategoriesByTagEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgSubCategoriesByTag, data);
};

export const RequestCategoriesByRevenueEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgCategoriesByRevenue, data);
};

export const RequestTopProductSpaceByRevenueEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgTopProductSpaceByRevenue, data);
};

export const RequestTopProductsByCategoryEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgTopProductsByCategory, data);
};

export const RequestTopProductsByProductSpaceEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgTopProductsByProductSpace, data);
};

export const RequestProductsByTagEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgProductsByTag, data);
};

export const RequestProductsByTopicEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgProductsByTopic, data);
};

export const RequestTopReviewsBySKUEndpoint = (data) => {
  return axiosPostRequestAppEndpoints(Api.umgReviewsBySkuid, data);
};
