import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router";
import { useDispatch, useSelector } from "react-redux";

// Import
import { RequestProfileEndpoint } from "../../components/AxiosEndpoints";
import { shuffle, validateArrayData, validateObjectData } from "../../utils";
import {
  SET_IS_MAIN_LOADING,
  SET_IS_LOADING_SPIN,
  SET_USER_PROFILE,
} from "../../redux/app/actions";

// Material UI
import { makeStyles } from "@material-ui/styles";
import { CircularProgress, Backdrop } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    background: "white",
  },
}));

const Initialize = ({ LayoutWrapperComponent, history, children }) => {
  const classes = useStyles();
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      RequestProfileEndpoint()
        .then((profileResponse) => {
          console.log("profileResponse", profileResponse);
          if (
            profileResponse.status === 200 &&
            validateObjectData(profileResponse.data)
          ) {
            // Set Profile Data
            let profileData = profileResponse.data["profileData"];
            dispatch({
              type: SET_USER_PROFILE,
              profileData,
            });
            dispatch({
              type: SET_IS_MAIN_LOADING,
              isMainLoading: false,
            });
            dispatch({
              type: SET_IS_LOADING_SPIN,
              isLoadingSpin: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: SET_IS_MAIN_LOADING,
            isMainLoading: false,
          });
          localStorage.removeItem("token");
          history.push("/login");
        });
    } else {
      dispatch({
        type: SET_IS_MAIN_LOADING,
        isMainLoading: false,
      });
      localStorage.removeItem("token");
      history.push("/login");
    }

    return () => {
      dispatch({
        type: SET_IS_MAIN_LOADING,
        isMainLoading: true,
      });
    };
  }, []);

  return (
    <>
      {!localStorage.getItem("token") && (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )}
      <Backdrop className={classes.backdrop} open={appState.isLoadingSpin}>
        <CircularProgress color="primary" />
      </Backdrop>
      {!appState.isMainLoading && (
        <LayoutWrapperComponent>{children}</LayoutWrapperComponent>
      )}
    </>
  );
};

Initialize.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(Initialize);
