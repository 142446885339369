import { dashboard } from "./dashboard";
import { analyze } from "./analyze";
import { consolidate } from "./consolidate";
import { artist } from "./artist";
//-----------------------|| MENU ITEMS ||-----------------------//

export const menuItem = (featuresAccessList, clientSelected) => {
  let items = [analyze(), artist]; //dashboard
  // items.push(consolidate);
  return {
    items,
  };
};
