import moment from "moment";
import Questions from "../mock-adapter/UMGMockData/QuestionsMockData.json";
import ZafulQuestions from "../mock-adapter/UMGMockData/zafulQuestionsData.json";
import FarfetchQuestions from "../mock-adapter/UMGMockData/farfetchQuestionsData.json";
import config from "../config";

// theme constant
export const gridSpacing = 3;
export const drawerWidth = 230;
export const appDrawerWidth = 320;
export const minDate = "2021-08-01";
export const maxDate = moment().format("YYYY-MM-DD");
export const chartsColors = ["#7d2a81", "#d2212f", "#efb31e", "#7EFA0B"];
export const chartsColorsV2 = ["#FE9D0B", "#D61F0C", "#FF7D24", "#859303"];

export const defaultQuestions = Questions;
export const defaultZafulQuestions = ZafulQuestions;
export const defaultFarfetchQuestions = FarfetchQuestions;

export const retailerType = ["Non apparel", "Apparel", "Artist", "Audience"];

const apparelRetailers = ["FARFETCH", "ZAFUL", "REVOLVE", "ASOS"];
const nonApparelRetailers = ["ACME", "CVS"]; //["MRS. MAYER", "MYPLENITY"]
const artistRetailers = [
  "MERCHBAR",
  "ARIANA GRANDE",
  "KACEY MUSGRAVES",
  "THE ROLLING STONES",
  "AMAZON ARTIST",
];

const audienceRetailers = ["AMAZON_", "TARGET"];

export const retailerOptions = {
  Apparel: apparelRetailers,
  "Non apparel": nonApparelRetailers,
  Artist: artistRetailers,
  Audience: audienceRetailers,
};

export const hierarchiData = {
  Apparel: { category: "sub_category_common", sub_category_common: "brand" },
  "Non apparel": {
    class: "category",
    category: "sub_category",
    sub_category: "product_space",
    product_space: "brand",
  },
  Artist: {},
  Audience: {},
};

export const artistList = [
  "Attar ayurveda",
  "Garnier",
  "Indus valley",
  "Revlon",
  "Streax",
];

export const nivoThemeOptions = {
  textColor: "#000000",
  fontSize: 12,
  fontFamily: config.fontFamily,
  axis: {
    domain: {
      line: {
        stroke: "#777777",
        strokeWidth: 0,
      },
    },
    ticks: {
      line: {
        stroke: "#777777",
        strokeWidth: 0,
      },
      text: {
        fontSize: 12,
        fill: "#333333",
      },
    },
  },
  grid: {
    line: {
      strokeWidth: 0,
    },
  },
  tooltip: {
    container: {
      background: "#ffffff",
      color: "#333333",
      fontSize: 12,
      fontFamily: config.fontFamily,
    },
  },
};

export const d3CommonQuadDimensions = {
  width: 280,
  height: 240,
  margin: { top: 10, right: 130, bottom: 75, left: 90 },
};
