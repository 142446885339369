import React, { lazy } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import ProfileInitialize from "../views/ProfileInitialize";
import ArtistLayout from "../layouts/ArtistLayout";
import PrivateRoute from "../components/PrivateRoute";
import Loadable from "../components/Loadable";
// dashboard routing

const ArtistPage = Loadable(
  lazy(() => import("../views/ArtistPage/artist.page"))
);
const ArtistDetailePage = Loadable(
  lazy(() => import("../views/ArtistDetailPage/artist-detail.page"))
);
const SOVMarketplace = Loadable(
  lazy(() =>
    import("../views/ArtistPage/SOVMarketplace/artist-sov-marketplace.page")
  )
);
const SocialMediaPresence = Loadable(
  lazy(() =>
    import(
      "../views/ArtistPage/SocialMediaPresence/artist-social-media-presence.page"
    )
  )
);
const Marketshare = Loadable(
  lazy(() => import("../views/ArtistPage/Marketshare/artist-marketshare.page"))
);
//-----------------------|| MAIN ROUTING ||-----------------------//

const ArtistRoutes = () => {
  const location = useLocation();

  return (
    <Route
      path={[
        "/artist/dashboard/sov-marketplace/detail",
        "/artist/dashboard/social-presence/detail",
        "/artist/dashboard/marketshare/detail",
        "/artist/dashboard/detail",
        "/artist/dashboard",
      ]}
    >
      <ProfileInitialize LayoutWrapperComponent={ArtistLayout}>
        <Switch location={location} key={location.pathname}>
          <PrivateRoute
            path="/artist/dashboard/sov-marketplace/detail"
            component={SOVMarketplace}
          />
          <PrivateRoute
            path="/artist/dashboard/social-presence/detail"
            component={SocialMediaPresence}
          />
          <PrivateRoute
            path="/artist/dashboard/marketshare/detail"
            component={Marketshare}
          />
          <PrivateRoute
            path="/artist/dashboard/detail"
            component={ArtistDetailePage}
          />
          <PrivateRoute path="/artist/dashboard" component={ArtistPage} />
        </Switch>
      </ProfileInitialize>
    </Route>
  );
};

export default ArtistRoutes;
