import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { makeStyles, useTheme } from "@material-ui/styles";
import { AppBar, CssBaseline, Toolbar, useMediaQuery } from "@material-ui/core";

// third-party
import clsx from "clsx";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";
import { drawerWidth } from "../../constants";
import {
  SET_MENU,
  SET_IS_LOADING_SPIN,
  SET_RETAILER,
  SET_CATEGORY_LIST,
  SET_CATEGORY,
  SET_QUESTIONS,
  SET_TOPIC_LIST,
  SET_TOPIC,
  SET_IS_QUESTIONS_LOADING,
} from "../../redux/app/actions";

import axios from "axios";

// assets
import {
  RequestCategoryBrandEndpoint,
  RequestQuestionsByTopicEndpoint,
} from "../../components/AxiosEndpoints";
import GlobalFiltersMobile from "../../components/GlobalFiltersMobile";
import withSearchComponent from "../../HOC/withSearchComponent";

// style constant
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: theme.palette.background.default,
  },
  appBarWidth: {
    transition: theme.transitions.create("width"),
    backgroundColor: theme.palette.background.default,
  },
  content: {
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("md")]: {
      marginLeft: -(drawerWidth - 20),
      width: `calc(100% - ${drawerWidth}px)`,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
      width: `calc(100% - ${drawerWidth}px)`,
      padding: "16px",
      marginRight: "10px",
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    [theme.breakpoints.down("md")]: {
      marginLeft: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
  },
}));

//-----------------------|| MAIN LAYOUT ||-----------------------//

const MainLayout = ({ children, handleGetTopics }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  // Handle left drawer
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const handleRetailerChange = (retailerSelected) => {
    if (retailerSelected && retailerSelected.length > 0) {
      console.log("retailerSelected", retailerSelected);

      dispatch({
        type: SET_IS_LOADING_SPIN,
        isLoadingSpin: true,
      });
      dispatch({
        type: SET_RETAILER,
        retailerSelected: "",
      });
      dispatch({
        type: SET_CATEGORY_LIST,
        categoryAccessList: [],
      });
      dispatch({
        type: SET_CATEGORY,
        category: "",
      });

      handleGetTopics({ retailer: retailerSelected });

      RequestCategoryBrandEndpoint({
        retailer: retailerSelected,
      })
        .then((res) => {
          if (res.status === 200) {
            dispatch({
              type: SET_RETAILER,
              retailerSelected,
            });
            dispatch({
              type: SET_CATEGORY_LIST,
              categoryAccessList: res.data["category"],
            });
            dispatch({
              type: SET_CATEGORY,
              category: [res.data["category"][0]],
            });
            dispatch({
              type: SET_IS_LOADING_SPIN,
              isLoadingSpin: false,
            });
          } else {
            dispatch({
              type: SET_IS_LOADING_SPIN,
              isLoadingSpin: false,
            });
            dispatch({
              type: SET_RETAILER,
              retailerSelected,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          dispatch({
            type: SET_IS_LOADING_SPIN,
            isLoadingSpin: false,
          });
          dispatch({
            type: SET_RETAILER,
            retailerSelected,
          });
        });
    } else {
      dispatch({
        type: SET_RETAILER,
        retailerSelected: "",
      });
      dispatch({
        type: SET_CATEGORY_LIST,
        categoryAccessList: [],
      });
      dispatch({
        type: SET_CATEGORY,
        category: "",
      });
    }
  };

  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !appState.opened });
  };

  React.useEffect(() => {
    dispatch({ type: SET_MENU, opened: !matchDownMd });
  }, [matchDownMd]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        className={appState.opened ? classes.appBarWidth : classes.appBar}
      >
        <Toolbar>
          <Header
            handleLeftDrawerToggle={handleLeftDrawerToggle}
            handleRetailerChange={handleRetailerChange}
          />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar
        drawerOpen={appState.opened}
        drawerToggle={handleLeftDrawerToggle}
        handleRetailerChange={handleRetailerChange}
      />

      {/* main content */}
      <main
        className={clsx([
          classes.content,
          {
            [classes.contentShift]: appState.opened,
          },
        ])}
      >
        {/* <GlobalFiltersMobile handleRetailerChange={handleRetailerChange} /> */}
        {/* <Main open={leftDrawerOpened}> */}

        <div>{children}</div>
      </main>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default withSearchComponent(MainLayout);
