import React from "react";
import ReactDOM from "react-dom";
import "react-perfect-scrollbar/dist/css/styles.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

// if (process.env.REACT_APP_ENABLE_MOCK === "true") {
//   /* eslint-disable global-require */
//   require("./mock-adapter");
//   /* eslint-enable global-require */
// }

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <I18nextProvider i18n={i18n}> */}
      <App />
      {/* </I18nextProvider> */}
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
