import React from "react";
import { Redirect, Switch } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import AuthenticationRoutes from "./AuthenticationRoutes";
import ConsolidatedRoutes from "./ConsolidatedRoutes";
import ArtistRoutes from "./ArtistRoutes";

// project imports
import config from "../config";
import NeedsOpporRoutes from "./NeedsOpporRoutes";

//-----------------------|| ROUTING RENDER ||-----------------------//

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to={config.defaultPath} />
      <React.Fragment>
        {/* Routes for authentication pages */}
        <AuthenticationRoutes />

        <ConsolidatedRoutes />

        {/* Routes for main layouts */}
        <MainRoutes />

        <ArtistRoutes />

        <NeedsOpporRoutes />
      </React.Fragment>
    </Switch>
  );
};

export default Routes;
