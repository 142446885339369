// assets
// import { IconDashboard } from "@tabler/icons";
import { Album } from "@material-ui/icons";

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const artist = {
  id: "artist",
  title: "Brand Insights",
  type: "group",
  children: [
    {
      id: "artist",
      title: "Brand Insights",
      type: "item",
      url: "/artist/dashboard",
      icon: Album,
      breadcrumbs: false,
      target: true,
    },
  ],
};
